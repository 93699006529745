import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Component,
  HostListener,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ConnectivityService } from '../../core/services/connectivity/connectivity.service';
import { SessionService } from '../../core/services/session/session.service';
import { SharedApisService } from '../../core/services/shared-apis/shared-apis.service';
import { ToastService } from '../../core/services/toast/toast.service';
import { BREAK_POINTS } from '../../shared/constants/break-points.constant';
import { ScreenType } from '../../shared/enums/screen-type.enum';
import { DelegatedUIErrorI } from '../../shared/interfaces/delegated-ui-error.interface';
import { FooterComponent } from '../layout/footer/footer.component';
import { HeaderComponent } from '../layout/header/header.component';
import { SideMenuComponent } from '../layout/side-menu/side-menu.component';

@Component({
  selector: 'app-base',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, FooterComponent, SideMenuComponent],
  templateUrl: './base.component.html',
  styleUrl: './base.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class BaseComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly sessionService: SessionService,
    private readonly renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private readonly platformID: Object,
    private readonly connectivityService: ConnectivityService,
    private readonly sharedApisService: SharedApisService,
    private readonly toastService: ToastService
  ) {
    inject(NgZone).runOutsideAngular(() => {
      this.connectivityService.startChecking();
    });
  }
  ngOnInit(): void {
    this.findLoggedInUser();
    this.initScreenType();
    this.initIsSideMenuListener();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event & { target: { innerWidth: number } }) {
    this.addScreenTypeAndIsSideMenuOpen(event.target?.innerWidth);
  }

  findLoggedInUser() {
    this.sharedApisService
      .findLoggedInUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (findLoggedInUserResponse) => {
          this.sessionService.addLoggedInUser(findLoggedInUserResponse.data);
        },
        error: (err: DelegatedUIErrorI) => {
          this.toastService.showError(err.description, err.title);
        },
      });
  }

  initIsSideMenuListener() {
    this.sessionService
      .getIsSideMenuOpen$()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (isSideMenuOpen) => {
          const appBase = this.renderer.selectRootElement('app-base', true);
          if (isSideMenuOpen) {
            this.renderer.setStyle(
              appBase,
              'grid-template-columns',
              '169px minmax(0, 1fr)'
            );
          } else {
            this.renderer.setStyle(
              appBase,
              'grid-template-columns',
              '60px minmax(0, 1fr)'
            );
          }
        },
      });
  }

  initScreenType() {
    if (isPlatformBrowser(this.platformID)) {
      this.addScreenTypeAndIsSideMenuOpen(
        this.document.defaultView!.innerWidth
      );
    }
  }

  addScreenTypeAndIsSideMenuOpen(width: number) {
    if (width < BREAK_POINTS.mobile) {
      this.sessionService.addIsSideMenuOpen(false);
      this.sessionService.addScreenType(ScreenType.MOBILE);
    } else if (width < BREAK_POINTS.tablet + 9) {
      this.sessionService.addIsSideMenuOpen(false);
      this.sessionService.addScreenType(ScreenType.TABLET);
    } else {
      this.sessionService.addIsSideMenuOpen(true);
      this.sessionService.addScreenType(ScreenType.PC);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
