import { NgClass, DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Component,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { SecondarySideMenuI } from '../../../departments/interfaces/secondary-side-menu.interface';
import { CourseI } from '../../interfaces/course.interface';
import { SIDE_MENU_ITEMS } from './constants/side-menu.constant';

@Component({
  selector: 'app-courses-details-side-menu',
  standalone: true,
  imports: [TranslateModule, NgClass, RouterLink],
  templateUrl: './courses-details-side-menu.component.html',
  styleUrl: './courses-details-side-menu.component.scss',
})
export class CoursesDetailsSideMenuComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private readonly platformID: Object,
    private readonly router: Router
  ) {}

  @Input('selectedCourse') selectedCourse?: CourseI;
  private readonly destroy$ = new Subject<void>();
  sideMenuItems = SIDE_MENU_ITEMS;
  isSideMenuOpen = false;

  ngOnInit(): void {
    this.initScreenType();
  }

  public getSideMenuItemIcon(sideMenuItem: SecondarySideMenuI) {
    return this.router.url ===
      '/' + sideMenuItem.route(this.selectedCourse?._id ?? '')
      ? sideMenuItem.activeIcon
      : sideMenuItem.nonActiveIcon;
  }

  public isCurrentRouteActive(sideMenuItem: SecondarySideMenuI) {
    if (sideMenuItem.alt === 'Home') {
      return this.router.url?.trim() === '' || this.router.url === 'home';
    } else {
      return (
        this.router.url ===
        '/' + sideMenuItem.route(this.selectedCourse?._id ?? '')
      );
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event & { target: { innerWidth: number } }) {
    this.isSideMenuOpen =
      event.target?.innerWidth <= 700 && event.target?.innerWidth >= 500;
  }

  initScreenType() {
    if (isPlatformBrowser(this.platformID)) {
      this.isSideMenuOpen =
        this.document.defaultView!.innerWidth <= 700 &&
        this.document.defaultView!.innerWidth >= 500;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
