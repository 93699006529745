import { Role } from '../../../../../shared/enums/role.enum';
import { SecondarySideMenuI } from '../../../../departments/interfaces/secondary-side-menu.interface';

export const SIDE_MENU_ITEMS: SecondarySideMenuI[] = [
  {
    translationPath: 'sideMenu.clos',
    activeIcon: 'assets/svgs/side-menu/active-course.svg',
    nonActiveIcon: 'assets/svgs/side-menu/non-active-course.svg',
    route: (courseID: string | number) => `courses/${courseID}/clos`,
    alt: 'Clos',
    roles: [
      Role.SUPER_ADMIN,
      Role.ADMIN,
      Role.DEAN,
      Role.DEPARTMENT_MANAGER,
      Role.PROGRAM_MANAGER,
    ],
  },
];
