import { NgFor, Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { RouterOutlet, ActivatedRoute } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil, finalize } from 'rxjs';
import { ToastService } from '../../../core/services/toast/toast.service';
import { StatCardsWithMainCardComponent } from '../../../shared/components/stat-cards-with-main-card/stat-cards-with-main-card.component';
import { CardI } from '../../../shared/interfaces/card.interface';
import { DelegatedUIErrorI } from '../../../shared/interfaces/delegated-ui-error.interface';
import { DepartmentsApiService } from '../departments-api.service';
import { DepartmentsService } from '../departments.service';
import { DepartmentI } from '../interfaces/department.interface';
import { DepartmentsDetailsSideMenuComponent } from './departments-details-side-menu/departments-details-side-menu.component';

@Component({
  selector: 'app-departments-details',
  standalone: true,
  imports: [
    DepartmentsDetailsSideMenuComponent,
    NgFor,
    StatCardsWithMainCardComponent,
    TranslateModule,
    RouterOutlet,
  ],
  templateUrl: './departments-details.component.html',
  styleUrl: './departments-details.component.scss',
})
export class DepartmentsDetailsComponent implements OnInit {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    private readonly translateService: TranslateService,
    private readonly toastService: ToastService,
    private readonly cd: ChangeDetectorRef,
    private readonly departmentsApiService: DepartmentsApiService,
    private readonly departmentsService: DepartmentsService
  ) {}

  departmentsCard: CardI[] = [
    {
      icon: 'course',
      number: 0,
      text: 'departments.cards.totalCourses',
    },
    {
      icon: 'student',
      number: 0,
      text: 'departments.cards.totalStudents',
    },
    {
      icon: 'instructor',
      number: 0,
      text: 'departments.cards.totalInstructors',
    },
  ];
  private readonly destroy$ = new Subject<void>();
  departmentID: string | undefined = undefined;
  selectedDepartment: DepartmentI | null = null;
  limit = 15;

  ngOnInit() {
    this.getDepartmentIDFromRouteParams();
  }

  getDepartmentIDFromRouteParams() {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((paramMap) => {
      const departmentID = paramMap.get('departmentID');
      if (!departmentID) {
        this.toastService.showError(
          this.translateService.instant('errors.contactTechnicalSupport')
        );
        this.location.back();
      } else {
        this.departmentID = departmentID;
        this.fetchDepartment();
      }
    });
  }

  fetchDepartment() {
    this.departmentsApiService
      .findOne(this.departmentID!)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.cd.detectChanges())
      )
      .subscribe({
        next: (findOneResponse) => {
          const { data } = findOneResponse;
          this.selectedDepartment = data;
          this.departmentsService.selectedDepartment.set(
            this.selectedDepartment
          );
        },
        error: (err: DelegatedUIErrorI) => {
          this.toastService.showError(err.description, err.title);
        },
      });
  }
}
