import { NgFor, Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { RouterOutlet, ActivatedRoute } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil, finalize } from 'rxjs';
import { ToastService } from '../../../core/services/toast/toast.service';
import { DelegatedUIErrorI } from '../../../shared/interfaces/delegated-ui-error.interface';
import { CoursesApiService } from '../courses-api.service';
import { CoursesService } from '../courses.service';
import { CourseI } from '../interfaces/course.interface';
import { CoursesDetailsSideMenuComponent } from './courses-details-side-menu/courses-details-side-menu.component';

@Component({
  selector: 'app-courses-details',
  standalone: true,
  imports: [
    CoursesDetailsSideMenuComponent,
    NgFor,
    TranslateModule,
    RouterOutlet,
  ],
  templateUrl: './courses-details.component.html',
  styleUrl: './courses-details.component.scss',
})
export class CoursesDetailsComponent implements OnInit {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    private readonly translateService: TranslateService,
    private readonly toastService: ToastService,
    private readonly cd: ChangeDetectorRef,
    private readonly coursesApiService: CoursesApiService,
    private readonly coursesService: CoursesService
  ) {}

  private readonly destroy$ = new Subject<void>();
  courseID: string | undefined = undefined;
  selectedCourse: CourseI | null = null;
  limit = 15;

  ngOnInit() {
    this.getCourseIDFromRouteParams();
  }

  getCourseIDFromRouteParams() {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((paramMap) => {
      const courseID = paramMap.get('courseID');
      if (!courseID) {
        this.toastService.showError(
          this.translateService.instant('errors.contactTechnicalSupport')
        );
        this.location.back();
      } else {
        this.courseID = courseID;
        this.fetchCourse();
      }
    });
  }

  fetchCourse() {
    this.coursesApiService
      .findOne(this.courseID!)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.cd.detectChanges())
      )
      .subscribe({
        next: (findOneResponse) => {
          const { data } = findOneResponse;
          this.selectedCourse = data;
          this.coursesService.selectedCourse.set(this.selectedCourse);
        },
        error: (err: DelegatedUIErrorI) => {
          this.toastService.showError(err.description, err.title);
        },
      });
  }
}
