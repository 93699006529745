<article
  [style.top]="this.isMobileScreen ? '3px' : '10px'"
  [className]="this.isRtl() ? 'right' : 'left'"
>
  <div #profileCard class="logged-in-user-container">
    <section class="logged-in-user-image-container">
      @if (!loggedInUser?.profilePicture?.url) {
      <div class="user-initials">
        <p>{{this.extractInitials(loggedInUser?.['user.name' | translate])}}</p>
      </div>
      } @else {
      <figure>
        <img [src]="loggedInUser?.profilePicture?.url" alt="profile picture" />
      </figure>
      }
    </section>
    <section
      [style.display]="this.isMobileScreen ? 'none' : 'flex'"
      class="logged-in-user-name-role"
    >
      <div class="name">
        <p>{{loggedInUser?.['user.name' | translate]}}</p>
      </div>
      <div class="role">
        <p>{{ roles[this.role] }}</p>
      </div>
    </section>
    <section class="arrow-container" (click)="this.openCloseDropDown()">
      <figure>
        <img
          [style.transform]="this.isDropDownOpen ? 'scaleY(-1)' : 'scaleY(1)'"
          src="assets/svgs/header/arrow-down.svg"
          alt="Arrow Down"
        />
      </figure>
    </section>
  </div>

  <nav
    class="main-nav-list"
    [hidden]="!this.isDropDownOpen"
    [style.width]="profileCard.offsetWidth + 'px'"
  >
    <ul>
      @for (dropDownItem of dropDownItems; track $index) {
      <li (click)="this.isDropDownOpen = false">
        <a
          [routerLink]="dropDownItem.route[0]"
          [ngClass]="{ active: this.isCurrentRouteActive(dropDownItem) }"
          ariaCurrentWhenActive="page"
        >
          <img
            [src]="this.getDropDownItemIcon(dropDownItem)"
            [alt]="dropDownItem.alt"
          />

          <span>{{ dropDownItem.translationPath | translate }}</span>
        </a>
      </li>

      }
    </ul>
  </nav>
</article>
