import { Location, NgClass } from '@angular/common';
import { Component, OnDestroy, OnInit, effect } from '@angular/core';
import { RouterLinkActive, ActivatedRoute } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from '../../../core/services/toast/toast.service';
import { DelegatedUIErrorI } from '../../../shared/interfaces/delegated-ui-error.interface';
import { CourseSectionsApiService } from '../course-sections-api.service';
import { CloAchievementsComponent } from './clo-achievements/clo-achievements.component';
import { PloAchievementsComponent } from './plo-achievements/plo-achievements.component';
import {
  Activity,
  AolCourseSection,
  Clo,
  Plo,
} from '../interfaces/aol-report-course-section.interface';
import { CourseSectionsService } from '../course-sections.service';
import { ActivitiesComponent } from './activities/activities.component';

@Component({
  selector: 'app-course-section-details',
  standalone: true,
  imports: [
    TranslateModule,
    NgClass,
    RouterLinkActive,
    CloAchievementsComponent,
    PloAchievementsComponent,
    ActivitiesComponent,
  ],
  templateUrl: './course-section-details.component.html',
  styleUrl: './course-section-details.component.scss',
})
export class CourseSectionDetailsComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  selectedCourseSection: AolCourseSection | null = null;
  currentPage: 'clo' | 'plo' | 'activities' = 'plo';
  selectedPlo: Plo | null = null;
  activities: Activity[] = [];
  constructor(
    private readonly courseSectionsApiService: CourseSectionsApiService,
    private readonly courseSectionsService: CourseSectionsService,
    private readonly location: Location,
    private readonly toastService: ToastService,
    private readonly route: ActivatedRoute,
    private readonly translateService: TranslateService
  ) {
    effect(() => {
      this.selectedPloListener();
    });
  }
  ngOnInit(): void {
    this.getDataFromRoute();
  }

  onCloSelected(clo: Clo) {
    this.activities = this.selectedCourseSection!.activities.filter(
      (activity) =>
        activity.questions.find((question) => question.clo === clo._id)
    );
    this.currentPage = 'activities';
  }

  getDataFromRoute() {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((paramMap) => {
      const courseSectionID = paramMap.get('courseSectionID');
      if (!courseSectionID) {
        this.toastService.showError(
          this.translateService.instant('errors.contactTechnicalSupport')
        );
        this.location.back();
      } else {
        this.fetchCourseSection(courseSectionID);
      }
    });
  }

  switchToPloPage() {
    this.currentPage = 'plo';
  }

  switchToCloPage() {
    this.currentPage = 'clo';
  }

  selectedPloListener() {
    if (!!this.courseSectionsService.selectedPlo()) this.switchToCloPage();
  }

  fetchCourseSection(courseSectionID: string) {
    this.courseSectionsApiService
      .aol(courseSectionID)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (findOneResponse) => {
          const { data } = findOneResponse;
          this.selectedCourseSection = data;
        },
        error: (err: DelegatedUIErrorI) => {
          this.toastService.showError(err.description, err.title);
        },
      });
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
