import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first, interval, Subject, takeUntil } from 'rxjs';
import { INTERCEPTOR_SKIP_CONSTANT } from '../../interceptors/constants/interceptor-skip.constant';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class ConnectivityService {
  private readonly destroy$ = new Subject<void>();
  public isConnected = true;

  constructor(
    private readonly http: HttpClient,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService
  ) {}

  startChecking() {
    interval(60000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const salt = new Date().getTime();
        this.http
          .get(`https://jsonplaceholder.typicode.com/todos?${salt}`, {
            observe: 'response',
            headers: {
              [INTERCEPTOR_SKIP_CONSTANT.AUTHENTICATION]: 'true',
              [INTERCEPTOR_SKIP_CONSTANT.ERRORS]: 'true',
              [INTERCEPTOR_SKIP_CONSTANT.LANGUAGE]: 'true',
              [INTERCEPTOR_SKIP_CONSTANT.REQUEST_COUNT]: 'true',
            },
          })
          .pipe(takeUntil(this.destroy$), first())
          .subscribe({
            next: (resp) => {
              if (resp.status === 200 && !this.isConnected) {
                this.isConnected = true;
                this.toastService.showSuccess(
                  this.translateService.instant(
                    'successes.internetConnected.description'
                  ),
                  this.translateService.instant(
                    'successes.internetConnected.title'
                  )
                );
              } else if (this.isConnected && resp.status !== 200) {
                this.isConnected = false;

                this.toastService.showError(
                  this.translateService.instant(
                    'errors.noInternet.description'
                  ),
                  this.translateService.instant('errors.noInternet.title')
                );
              }
            },
            error: () => {
              this.isConnected = false;
              this.toastService.showError(
                this.translateService.instant('errors.noInternet.description'),
                this.translateService.instant('errors.noInternet.title')
              );
            },
          }),
          (_: any) => {
            this.isConnected = false;
            this.toastService.showError(
              this.translateService.instant('errors.noInternet.description'),
              this.translateService.instant('errors.noInternet.title')
            );
          };
      });
  }

  stopChecking() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
