import { NavItemI } from '../../../../../shared/interfaces/nav-item.interface';

export const DROP_DOWN_ITEMS: NavItemI[] = [
  {
    translationPath: 'sideMenu.settings',
    activeIcon: 'assets/svgs/side-menu/active-settings.svg',
    nonActiveIcon: 'assets/svgs/side-menu/non-active-settings.svg',
    route: ['settings/change-password'],
    alt: 'Settings',
  },
  {
    translationPath: 'sideMenu.profile',
    activeIcon: 'assets/svgs/side-menu/active-profile.svg',
    nonActiveIcon: 'assets/svgs/side-menu/non-active-profile.svg',
    route: ['profile'],
    alt: 'Profile',
  },
];
