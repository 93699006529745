import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { StorageService } from '../../services/storage/storage.service';

export const loggedInGuard: CanActivateFn = (_route, _state) => {
  const storageService = inject(StorageService);
  const router = inject(Router);

  if (!!storageService.accessToken) return true;

  router.navigate(['auth/login']);
  return false;
};
