import {
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { isServer } from '../../../shared/constants/is-server.constant';
import { INTERCEPTOR_SKIP_CONSTANT } from '../constants/interceptor-skip.constant';

export const languageInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) => {
  const skipIntercept = req.headers.has(INTERCEPTOR_SKIP_CONSTANT.LANGUAGE);
  if (skipIntercept) return next(req);

  if (isServer) return next(req);
  const modifiedReq = req.clone({
    headers: req.headers.set(
      'Accept-Language',
      localStorage.getItem('language') ?? 'en'
    ),
  });

  return next(modifiedReq);
};
