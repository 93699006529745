<article class="cards-container">
  <section class="main-card">
    <div class="image">
      @if (!this.config().mainCard.img) {

      <img src="assets/images/code-mint-logo.png" alt="PSUT Logo" />

      } @else {

      <img [src]="this.config().mainCard.img" alt="PSUT Logo" />

      }
    </div>
    <div class="info">
      <div>
        <p class="intro">Name:</p>
        <p>{{ this.config().mainCard.name }}</p>
      </div>
      <div>
        <p class="intro">Manager:</p>
        <p>
          {{
            !this.config().mainCard.manager
              ? ("common.notAssigned" | translate)
              : this.config().mainCard.manager
          }}
        </p>
      </div>
    </div>
  </section>
  @for (card of this.config().cards; track $index ) {

  <section class="stat-card">
    <div class="icon">
      <img [src]="'assets/svgs/cards/' + card.icon + '.svg'" alt="" />
      <div>
        <p>{{ card.number }}</p>
      </div>
    </div>
    <div>
      <p>{{ card.text | translate }}</p>
    </div>
  </section>
  }
</article>
